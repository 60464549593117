<template>
  <v-dialog persistent v-model="show" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="title font-weight-medium">Update Proposal</span>
        <v-spacer />
        <transition name="slide-fade">
          <v-chip
            v-if="showUpdateMessage"
            dark
            small
            color="success"
            py-0
          >
          <v-icon class="pr-1">check_circle</v-icon>
          <span>Successfully updated the proposal.</span>
        </v-chip>
        </transition>
      </v-card-title>
      <v-divider></v-divider>
        <v-form ref="updateProposalForm">
          <v-layout wrap class="px-4 pt-4">
            <v-flex xs12>
              <v-textarea
                outline
                label="Proposal Message"
                v-model="compMessage"
                :rules="proposalMessageRule"
              ></v-textarea>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                outline
                mask="############"
                label="Amount"
                v-model="compAmount"
                :rules="proposalAmountRule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 :pl-3="$vuetify.breakpoint.mdAndUp">
              <v-select
                outline
                label="Follow-ups"
                item-value="text"
                :items="followUpItems"
                v-model="followUpsSelected"
                :rules="followUpsRule"
                @change="addFollowUp"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      <v-card-actions class="pa-3 lightBlack">
        <v-btn text @click="closeModal">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" @click="updateProposal" :loading="isUpdating">Update Proposal</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'visible',
    'proposalId',
    'message',
    'followUps',
    'amount'
  ],
  data () {
    return {
      isUpdating: false,
      showUpdateMessage: false,
      dialog: false,
      compAmount: '',
      compMessage: '',
      compFollowUps: '',
      followUpsSelected: '',
      followUpItems: [
        { shortCode: '0', text: '0 Follow-ups' },
        { shortCode: '1', text: '1 Follow-ups' },
        { shortCode: '2', text: '2 Follow-ups' },
        { shortCode: '3', text: '3 Follow-ups' },
        { shortCode: '4', text: '4 Follow-ups' },
        { shortCode: '5', text: '5 Follow-ups' }
      ],
      proposalAmountRule: [
        (v) => !!v || 'Please enter an amount.',
        (v) => v >= 200 || 'Minimum amount is ₱200.'
      ],
      proposalMessageRule: [
        (v) => (v && v.length >= 100) || 'Please enter at least 100 characters.',
        (v) => (v && v.length <= 1500) || 'Please enter at most 150 0 characters.'
      ],
      followUpsRule: [
        (v) => !!v || 'Please enter a follow-up(s).'
      ]
    }
  },
  methods: {
    init () {
      this.compMessage = this.message
      this.compAmount = this.amount
      this.compFollowUps = `${this.followUps}`
      this.followUpsSelected = `${this.followUps} Follow-ups`
    },
    addFollowUp (event) {
      this.compFollowUps = parseInt(event.charAt(0))
      this.followUpsSelected = event
    },
    updateProposal () {
      if (this.$refs.updateProposalForm.validate()) {
        this.isUpdating = true
        let proposal = {
          _id: this.proposalId,
          message: this.compMessage,
          amount: this.compAmount,
          followUps: this.compFollowUps
        }

        this.$store.dispatch('proposals/UPDATE_USER_PROPOSAL', proposal)
          .then(() => {
            this.showUpdateMessage = true
            setTimeout(() => {
              this.showUpdateMessage = false
            }, 2000)
          })
          .finally(() => {
            this.isUpdating = false
          })
      }
    },
    closeModal () {
      this.$emit('close')
      this.$refs.updateProposalForm.reset()
    }
  },
  created () {
    this.init()
  },
  computed: {
    show () {
      return this.visible
    }
  }
}
</script>

<style scoped>
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  {
    transform: translateX(-10px);
    opacity: 0;
  }
</style>
